import React from 'react';
import { Box, capitalize, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { getFormattedDate, getFromNowTime, getLocaleYears } from '../../../utils/dateTimeUtils';

const useStyles = makeStyles((theme) => ({
  datetime: {
    padding: '0 .75em .25em',
    textAlign: 'right',
    fontSize: '.75em',
    color: theme.palette.grey.A200,
    '@media (max-width:600px)': {
      paddingTop: '.75em',
    },
  },
  box: {
    width: 'calc(100% - 2em)',
    flexGrow: 1,
    padding: '1em 1em 0',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  container: {
    '@media (min-width:601px)': {
      '& .MuiGrid-root': {
        '& .MuiBox-root': {
          height: '100%',
          padding: '0 1em',
          borderRight: `1px solid ${theme.palette.primary.main}`,
        },
        '&:first-child .MuiBox-root': {
          marginLeft: '-.75em',
        },
        '&:last-child .MuiBox-root': {
          borderRight: 'none',
        },
      },
    },
    '@media (max-width:600px)': {
      '& .MuiGrid-root': {
        '& .MuiBox-root': {
          width: '100%',
          padding: '.625em 0',
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '&:first-child .MuiBox-root': {
          paddingTop: 0,
        },
        '&:last-child .MuiBox-root': {
          paddingBottom: 0,
          borderBottom: 'none',
        },
      },
    },
  },
  measures: {
    display: 'flex',
    alignItems: 'flex-end',
    '& div:nth-child(1)': {
      fontSize: '.9375em',
      color: theme.palette.grey.A700,
    },
    '& div:nth-child(2n)': {
      margin: '.125em 0 0 auto',
      paddingRight: 8,
      fontSize: '1.125em',
      fontWeight: 700,
    },
  },
  age: {
    marginBottom: '.625em',
  },
  subtitle: {
    margin: '.4375em 0 .5625em',
  },
  bmi: {
    marginTop: '-.25em !important',
    fontSize: '.75em !important',
    fontWeight: '600 !important',
  },
}));

const getBmiLabel = (bmi) => {
  if (bmi < 18.50) return 'underweight';
  if (bmi < 24.99) return 'normal';
  if (bmi < 29.99) return 'overweight';
  if (bmi < 34.99) return 'obese';
  return 'extremely.obese';
};

export const LastVitalSigns = ({ dob, explorations }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const last = explorations && explorations.length && explorations[0];

  const age = getLocaleYears(moment(dob));
  const bmi = last && last.height && (last.weight / ((last.height / 100) ** 2)).toFixed(2);
  const spO2 = last && last.oxygenSaturation && last.oxygenSaturation.toFixed(2);

  return !last ? null : (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper elevation={2}>
          <Box className={classes.box}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Box>
                  <div className={`${classes.measures} ${classes.age}`}>
                    <div>{`${capitalize(t('age'))}:`}</div>
                    <div>{age}</div>
                  </div>
                  <div className={classes.measures}>
                    <div>{`${capitalize(t('height'))}:`}</div>
                    <div>{`${last.height} cm`}</div>
                  </div>
                  <div className={classes.measures}>
                    <div>{`${capitalize(t('weight'))}:`}</div>
                    <div>{`${last.weight} kg`}</div>
                  </div>
                  <div className={classes.measures}>
                    <div>{`${t('bmi')}:`}</div>
                    <div>{bmi}</div>
                  </div>
                  <div className={classes.measures}>
                    <div />
                    <div className={classes.bmi}>{t(getBmiLabel(bmi))}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <div className={`${classes.measures} ${classes.subtitle}`}>
                    <div>{`${capitalize(t('blood.pressure'))}:`}</div>
                    <div />
                  </div>
                  <div className={classes.measures}>
                    <div>{`${capitalize(t('systolic'))}:`}</div>
                    <div>{`${last.bloodPressureSystolic} mm[Hg]`}</div>
                  </div>
                  <div className={classes.measures}>
                    <div>{`${capitalize(t('diastolic'))}:`}</div>
                    <div>{`${last.bloodPressureDiastolic} mm[Hg]`}</div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box>
                  <div className={`${classes.measures} ${classes.subtitle}`}>
                    <div>{`${capitalize(t('indirect.oximetry'))}:`}</div>
                    <div />
                  </div>
                  <div className={classes.measures}>
                    <div>{`${capitalize(t('o2.saturation'))}:`}</div>
                    <div>{`${spO2} %`}</div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <div className={classes.datetime}>
              {`${getFromNowTime({ date: last.createdAt })} (${getFormattedDate({ date: last.createdAt })})`}
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LastVitalSigns;
