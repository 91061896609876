import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';
import { CloudDownload, Delete, ViewCarousel } from '@material-ui/icons';

import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { KBytesPrettify } from '../../../utils/BytesPrettifyUtils';
import { downloadUri, getFilenameExtension, getUrlExtension, imageExtensions } from '../../../utils/FileUtils';
import { Image } from '../../Image/Image';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  image: {
    maxHeight: 32,
  },
  subtitle: {
    margin: '.25em .375em 0',
    fontSize: '0.75em',
    color: theme.palette.grey.A200,
  },
}));

const FilesCollapse = ({ item }) => {
  const file = item.node ? item.node : item;
  const styles = useStyles();

  return !!imageExtensions.includes(getUrlExtension(file.file)) && (
    <Box className={styles.wrapper}>
      <Image styles={styles.image} src={file.file} alt={file.fileName} />
    </Box>
  );
};

const PatientFiles = ({ files, deleteRequest }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.hospital);

  const getTitle = (item) => (
    `${item.fileName}`
  );

  const getSubTitle = (item) => (
    <div className={classes.subtitle}>
      {KBytesPrettify(item.fileSize).string}
    </div>
  );

  const handleDownload = (evt, index) => {
    evt.stopPropagation();
    const node = files[index];

    downloadUri(node.file, node.fileName);
  };

  const handleView = (evt, index) => {
    evt.stopPropagation();
    const images = [files[index].file];

    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  };

  const handleDelete = (evt, index) => {
    evt.stopPropagation();
    deleteRequest(index);
  };

  const isImage = (item) => !!imageExtensions.includes(getFilenameExtension(item.fileName));
  const isNotImage = (item) => !isImage(item);

  const emptyList = !files || !files.length;
  const PatientFilesList = () => {
    if (emptyList) return <div style={{ margin: '.5em 1em' }}>{t('no.patient.files')}</div>;

    const sortedFiles = [...files].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const ActionButtons = [
      { Icon: ViewCarousel, tooltip: 'view', onClick: handleView, disabled: isNotImage },
      { Icon: CloudDownload, tooltip: 'download', onClick: handleDownload },
      { Icon: Delete, tooltip: 'delete', onClick: handleDelete },
    ];

    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sortedFiles}
        GetTitle={getTitle}
        GetSubtitle={getSubTitle}
        GetCollapse={FilesCollapse}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleDownload}
        ActionButtons={ActionButtons}
      />
    );
  };

  return hospital && hospital.menuOptionFiles && (
    <InfoAccordion title={t('files')} Item={PatientFilesList} expanded={!emptyList} />
  );
};

export default PatientFiles;
