import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 16,
      width: '100%',
      maxWidth: 600,
    },
  },
  subheader: {
    borderBottom: '1px solid #0002',
  },
  container: {
    maxWidth: '100%',
    padding: '.75em 2.5em',
    '@media (max-width:600px)': {
      padding: '.75em 1.5em',
    },
    '@media (max-width:450px)': {
      padding: '.75em',
    },
  },
  wrapper: {
    width: 'calc(100% - 40px)',
    maxWidth: 600,
    margin: '1em auto 1em',
    padding: '.5em 1.5em',
    '@media (max-width:600px)': {
      width: 'calc(100% - 10px)',
      margin: '.5em 0',
      padding: '.5em .25em',
    },
  },
}));

export default useStyles;
