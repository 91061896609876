import React, { useState, forwardRef } from 'react';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@material-ui/core';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import { Row, Column } from '../../Common/styled/Groups';
import { Form } from '../../Common/styled/Form';
import Loading from '../../Common/Loading';
import { INVITE_PATIENT } from '../../../graphql/mutations';
import { TextFieldUI } from '../../../componentsUI/TextField';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const InvitePatientDialog = ({ open, onClose, patient = {} }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [inviteError, setInviteError] = useState(false);
  const [inviteCalled, setInviteCalled] = useState(false);
  const language = i18n && i18n.language;
  const classes = useStyles();

  const closeForm = () => {
    setServerError(false);
    setInviteError(false);
    setInviteCalled(false);
    onClose();
  };

  const [invitePatient, { loading, called }] = useMutation(INVITE_PATIENT, {
    onCompleted() {
      toast(t('patient.has.received.invitation'), { className: 'toast-success' });
      closeForm();
    },
    onError(e) {
      const errorMessage = networkErrorParse(e);
      setInviteCalled(called);
      setInviteError(errorMessage);
      setServerError(errorMessage === 'you.are.not.allowed.to.perform.this.action'
        ? 'permission.error'
        : 'server.error');
    },
  });

  const validationSchema = Yup.object().shape({
    userEmail: Yup.string()
      .required(t('required.field'))
      .email(t('invalid.email')),
  });

  const initialValues = {
    userEmail: patient.email || '',
  };

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    setInviteError(false);
    try {
      await invitePatient({
        variables: {
          userEmail: values.userEmail.toLowerCase(),
          patientUuid: patient.uuid,
          language,
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeForm}
    >
      <DialogTitle>{t('invite.patient')}</DialogTitle>
      <DialogContent>
        <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(props) => (
            <Form id="formInvite" autoComplete="off" onSubmit={props.handleSubmit}>
              <Row className="mb-4">
                <Column>
                  <TextFieldUI name="userEmail" label={t('email')} props={props} classes={classes} />
                </Column>
              </Row>

              {(loading || inviteCalled) && !serverError && <Loading />}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {inviteError && <AlertUI severity="error" title="Error">{t(inviteError)}</AlertUI>}
                </Grid>
                <Grid item xs={12}>
                  {serverError === 'server.error' && <AlertUI severity="info" title="Info">{t(serverError)}</AlertUI>}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={closeForm}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          form="formInvite"
          className={classes.button}
          disabled={(loading || inviteCalled) && !serverError}
          color="primary"
        >
          {t('invite')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
