import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Chip, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined, Web } from '@material-ui/icons';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { DATE_FORMAT, GetFormat, Size } from '../../../utils/functions';
import { webViewerEndpoints } from '../../../../config';

const useStyles = makeStyles((theme) => ({
  data: {
    fontSize: '.875em',
    '& > *': {
      marginRight: 8,
    },
    '& .title': {
      fontWeight: 700,
      marginLeft: 6,
      color: theme.palette.primary.main,
    },
    '& .size': {
      fontWeight: 600,
      fontSize: '1.05em',
      color: theme.palette.grey.A700,
    },
    '& .MuiChip-root': {
      position: 'relative',
      top: -3,
      marginRight: 4,
      marginBottom: 1,
      height: 24,
      borderRadius: 6,
      '& > span': {
        lineHeight: 1.3,
        padding: '0 8px',
      },
    },
  },
  datetime: {
    margin: '-2px 0 -8px 6px',
    color: theme.palette.grey.A200,
    '& > span': {
      fontSize: '1.0625rem',
    },
  },
}));

const PatientDicomStudies = ({ dicomStudies }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);

  const getTitle = (dicomStudy) => (
    <Box className={classes.data}>
      {dicomStudy.modalities.map((modality, key) => (
        <Chip key={key.toString()} label={modality} color="primary" />
      ))}
      <Chip label={`${dicomStudy.numberOfSeries} ${t('series')}`} />
      <Chip label={`${dicomStudy.numberOfInstances} ${t('instances')}`} />
      <Typography component="span" className="title">{dicomStudy.title}</Typography>
      <Typography component="span" className="size">{Size(dicomStudy.storageSize)}</Typography>
    </Box>
  );

  const getSubtitle = (dicomStudy) => (
    <Box className={classes.datetime}>
      <Typography component="span">{GetFormat(dicomStudy.createdAt, DATE_FORMAT)}</Typography>
    </Box>
  );

  const sortedStudies = [...dicomStudies].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexStudy = sortedStudies[index];

    navigate(`/study/${indexStudy.uuid}`);
  };

  const openInWebViewer = (evt, index) => {
    evt.stopPropagation();

    const isBrowser = () => typeof window !== 'undefined';
    const study = sortedStudies[index];
    const url = `${webViewerEndpoints.getStudy}${study.studyInstanceUid}`;

    if (isBrowser) window.open(url, '_blank');
  };

  const ActionButtons = [
    { Icon: Web, tooltip: 'open.in.viewer', onClick: openInWebViewer },
    { Icon: InfoOutlined, tooltip: 'study.info' },
  ];

  const emptyList = !dicomStudies || !dicomStudies.length;
  const dicomStudiesList = () => {
    if (emptyList) return <div style={{ margin: '.5em 1em' }}>{t('patient.no.studies')}</div>;

    return (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sortedStudies}
        GetTitle={getTitle}
        GetSubtitle={getSubtitle}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
        ActionButtons={ActionButtons}
      />
    );
  };

  return hospital && hospital.menuOptionStudies && (
    <InfoAccordion title={t('dicom.studies')} Item={dicomStudiesList} expanded={!emptyList} />
  );
};

export default PatientDicomStudies;
