import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import InfoAccordion from '../../../componentsUI/InfoAccordion';
import ArchivesTableCollapse from '../../../componentsUI/ArchivesTableCollapse';
import { GetFormat } from '../../../utils/functions';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 2em 1em',
  },
  noItem: {
    margin: '.25em',
    fontSize: '.875em',
    color: '#909090',
  },
}));

const InstructionsCollapse = ({ item }) => {
  const note = item.node ? item.node : item;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div>{note.comment}</div>
    </div>
  );
};

const Instructions = ({ values }) => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const getTitle = (item) => (
    `${item.title}`
  );

  const getSubTitle = (item) => (
    `${GetFormat(item.createdAt, 'DATEFORMAT')}`
  );

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = values[index];

    // NOTE: display
    navigate(`/case/${indexCase.uuid}`);
  };

  const sortedValues = [...values].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  const InstructionsList = () => (
    values && values.length ? (
      <ArchivesTableCollapse
        responsive={false}
        fieldNames={null}
        items={sortedValues}
        GetTitle={getTitle}
        GetSubtitle={getSubTitle}
        GetCollapse={InstructionsCollapse}
        selected={selected}
        setSelected={setSelected}
        handleGoto={handleGoto}
      />
    ) : (
      <div style={{ margin: '.5em 1em' }}>{t('no.orders.instructions')}</div>
    )
  );

  return (
    <InfoAccordion title={t('orders.instructions')} Item={InstructionsList} />
  );
};

export default Instructions;
