import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Loading from '../../Common/Loading';

import { Column } from '../../Common/styled/Groups';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { ExtraBold } from '../../Common/styled/Text';
import { Margin } from '../../Common/styled/Margins';
import { GET_RECENT_MEDICAL_CASES } from '../../../graphql/queries';
import { DELETE_PATIENT } from '../../../graphql/mutations';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeletePatientDialog = ({ open, onClose, refetch = () => {}, patient = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [save, setSave] = useState(false);
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const { uuid: patientUuid } = patient;
  const patientName = patient && `${patient.name} ${patient.surname}`;

  const [deletePatient] = useMutation(DELETE_PATIENT, {
    onCompleted() {
      refetch();
      onClose(true);
      setSave(false);
    },
    refetchQueries: [{
      query: GET_RECENT_MEDICAL_CASES,
      variables: {
        hospitalUuid,
        first: 5,
        orderBy: {
          field: 'CREATED_AT',
          direction: 'DESC',
        },
      },
    }],
  });

  const handleDelete = () => {
    setSave(true);
    deletePatient({ variables: { patientUuid } });
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('patient.confirm.delete')}</DialogTitle>
      {(save) ? (
        <Loading />
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              {t('patient.delete.confirmation.message')}
            </DialogContentText>
            <Margin mx-0 mt-5>
              <Column>
                <GreyLabel>{`${t('patient.name')}:`}</GreyLabel>
                <ExtraBold>{patientName}</ExtraBold>
              </Column>
            </Margin>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" className={classes.button} color="primary" onClick={onClose}>{t('cancel')}</Button>
            <Button variant="contained" className={classes.button} color="primary" onClick={handleDelete}>{t('delete')}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
